import React from "react";
import "./HomepageGoogleReview.module.scss";
import { REVIEW_LIFEPAL_JSON } from "../../../shared/constants";
import HomepageSlider from "../../../components/Slider/HomepageSlider";

const HomepageGoogleReview = () => (
  <div className="g-review-wrapper">
    <div className="g-review-header">
      <h2 className="g-review-title">Testimoni Positif: Cerita Kepuasan Nyata Nasabah Lifepal</h2>
      <div className="g-review-google-card">
        <img
          src={"/static/images/homepage/google.svg"}
          alt="Lifepal Google Review"
          className="g-review-google-card-image"
        />
        <div className="google-card-detail">
          <p className="google-cart-title">
            52 Ribu Pelanggan Puas Dengan Lifepal!
          </p>
          <div className="google-cart-rate">
            <img
              className="google-star"
              src={"/static/images/homepage/star-rate.svg"}
              alt=""
            />
            <p className="google-start-text">4.6/5.0</p>
          </div>
        </div>
      </div>
    </div>
    <div className="g-review-card-wrapper">
      <HomepageSlider
        sliderData={{
          desktop: {
            height: 172,
            width: 262,
            visibleItems: 4,
          },
          mobile: {
            height: 152,
            width: 312,
          },
        }}
        items={REVIEW_LIFEPAL_JSON.map((item, index) => (
          <div
            className="g-review-card-item"
            key={`g-review-card-item-${index}`}
          >
            <img
              src={"/static/images/homepage/double-quot.svg"}
              alt=""
              className="g-review-card-icon"
            />
            <div className="g-review-card">
              <p className="g-review-card-title">{item.name}</p>
              <p className="g-review-card-comment">{item.review}</p>
            </div>
          </div>
        ))}
      />
    </div>
  </div>
);

export default HomepageGoogleReview;
